<template>
    <div class="event-member-actions">
        <BaseButton
            v-if="
                authStore.loggedIn &&
                    event.isFullyBooked &&
                    isInWaitingList &&
                    !hasReservation
            "
            class="button--secondary"
            @click="openReservationModal"
        >
            Op wachtlijst
            <template #after>
                <BaseIcon icon="check" />
            </template>
        </BaseButton>

        <BaseButton
            v-else-if="(authStore.loggedIn && !hasReservation) || event.isCancelled"
            :disabled="!!disabled"
            class="button--secondary"
            @click="openReservationModal"
        >
            {{ reserveText }}

            <template
                v-if="showButtonIcon"
                #after
            >
                <BaseIcon icon="arrow-right" />
            </template>
        </BaseButton>

        <BaseButton
            v-else-if="authStore.loggedIn && hasReservation"
            class="button--secondary"
            @click="openReservationModal"
        >
            Gereserveerd
            <template #after>
                <BaseIcon icon="check" />
            </template>
        </BaseButton>

        <BaseButton
            v-else-if="!authStore.loggedIn"
            :disabled="!!disabled"
            class="button--secondary"
            @click="modalStore.open({ name: MODAL_LOGIN })"
        >
            {{ reserveText }}
            <template
                v-if="showButtonIcon"
                #after
            >
                <BaseIcon icon="arrow-right" />
            </template>
        </BaseButton>

        <ToggleButton
            :value="hasFavorite ? [props.event.id] : []"
            :toggle-value="event.id"
            class="toggle-button--favorite"
            @input="handleFavoriteSelect"
        >
            <template #icon-inactive>
                <BaseIcon icon="save" />
            </template>

            <template #icon-active>
                <BaseIcon icon="saved" />
            </template>

            {{ hasFavorite ? 'Bewaard' : 'Bewaar' }}
        </ToggleButton>
    </div>
</template>

<script setup lang="ts">
import { MODAL_LOGIN, MODAL_RESERVATION, useModalStore } from '~/store/modal';
import * as RESERVATION_TYPES from '~/constants/reservationTypes.js';
import { useUserStore } from '~/store/user';
import { useEventsStore } from '~/store/events';
import type { EventDate } from '~/@types/data';
import { useAuthStore } from '~/store/auth';
import { STATUS } from '~/constants/errorMessages';

const props = defineProps<{
    event: EventDate;
}>();

const authStore = useAuthStore(),
    userStore = useUserStore(),
    modalStore = useModalStore(),
    eventsStore = useEventsStore();

const savingFavorite = ref(false);
const errorMessage = ref<string|null>(null);

const isInWaitingList= computed(() => userStore.isInWaitingList(props.event.id));

const hasReservation = computed(() => userStore.hasReservation(props.event.id));

const hasFavorite = computed(() => userStore.hasFavorite(props.event.id));

const isWapresFullyBooked = computed(() => {
    return props.event.reservationType === RESERVATION_TYPES.WAPRES
        && props.event.isFullyBooked
        && !props.event.doorsaleEnabled;
});

const reservationState = computed(() => {
    if (props.event.isCancelled) {
        return 'cancelled';
    }

    if (props.event.isSoldout) {
        return 'sold-out';
    }

    if (isWapresFullyBooked.value && !isInWaitingList.value && props.event.waitingListEnabled) {
        return 'waiting-list';
    }

    if (isWapresFullyBooked.value) {
        return 'fully-booked';
    }

    if (!props.event.isActive) {
        return 'inactive';
    }

    if (!authStore.loggedIn) {
        return 'log-in';
    }

    return 'default';
});

const reserveText = computed(() => {
    if (reservationState.value === 'cancelled') {
        return 'Dit programma is afgelast';
    }

    if (reservationState.value === 'sold-out') {
        return 'Dit programma is uitverkocht';
    }

    if (reservationState.value === 'fully-booked') {
        return 'Volgereserveerd';
    }

    if (reservationState.value === 'inactive') {
        return 'Dit programma is al geweest';
    }

    if (reservationState.value === 'log-in') {
        return 'Log in om te reserveren';
    }

    return 'Tickets';
});

const showButtonIcon = computed(() => {
    if (reservationState.value !== 'default') {
        return false;
    }

    return true;
});

const disabled = computed(() => {
    const disabledStates = [
        'sold-out',
        'fully-booked',
        'inactive',
        'cancelled'
    ];

    return disabledStates.includes(reservationState.value);
});

const openReservationModal = () => {
    modalStore.open(MODAL_RESERVATION);
};

const handleFavoriteSelect = async() => {
    if (!useAuthStore().loggedIn) {
        modalStore.open({ name: MODAL_LOGIN });
        return;
    }

    savingFavorite.value = true;

    const { error } = await useFetchLaravel(`/v2.0/user/me/favorite/${props.event.id}`, {
        method: hasFavorite.value ? 'DELETE' : 'POST'
    });

    if (error.value?.statusCode === 500) {
        errorMessage.value = STATUS['500'];
    }

    // Refresh the user, so we have the most recent favorites.
    eventsStore.updateMyListHome();

    await useGetAndSetMember();

    savingFavorite.value = false;
};
</script>

<style lang="less" src="./EventMemberActions.less" />
