<template>
    <StateSwitch :state="status">
        <template #default>
            <slot
                name="default"
                :event="data?.data"
            />
        </template>
    </StateSwitch>
</template>

<script setup lang="ts">
import type { EventDate } from '~/@types/data';
import { useEventsStore } from '~/store/events';

const props = defineProps<{
    eventId: string|number;
}>();

const eventsStore = useEventsStore();

const fetchEventUrl = computed(() => {
    return `/v2.0/events/${props.eventId}`;
});

const { data, status, error } = await useFetchLaravel<{ data: EventDate }>(
    fetchEventUrl.value,
    {
        params: {
            use_cache: eventsStore.needsRefresh === 0
        },
        watch: [() => props.eventId, () => eventsStore.needsRefresh]
    }
);

const seoDescription = computed(() => {
    if (data.value?.data && data.value?.data.description) {
        // regular expression to strip html tags for the seo description
        return data.value.data.description.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '');
    }

    return null;
});

watch(data, () => {
    if (data.value) {
        eventsStore.setActiveEventData(
            data.value.data,
            fetchEventUrl.value
        );
    }
}, {
    immediate: true
});

if (!data.value?.data) {
    throw createError({ statusCode: 404, statusMessage: JSON.stringify(error.value) });
} else {
    useHead({
        title: data.value.data.title,
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: seoDescription.value
            },
            {
                hid: 'author',
                name: 'author',
                content: 'We Are Public'
            },
            {
                hid: 'og:image',
                property: 'og:image',
                content: data.value.data.featuredImage?.url_box_lg
            },
            {
                hid: 'og:title',
                property: 'og:title',
                content: data.value.data.title
            },
            {
                hid: 'og:description',
                property: 'og:description',
                content: seoDescription.value
            },
            {
                hid: 'twitter:image',
                name: 'twitter:image',
                content: data.value.data.featuredImage?.url_box_lg
            }
        ]
    });
}

watch(data.value, () => {
    if (import.meta.server) {
        return;
    }

    setTimeout(function() {
        document.querySelector('.event-modal')?.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, 300);
}, {
    immediate: true
});
</script>
