<template>
    <EventBundleSection
        v-if="event.start_date && !event.end_date && eventHasCity"
        :lazy="true"
        :event-bundle-query="relatedCityEventBundle"
        presentation="slider"
        section-width="bleed"
    >
        {{ `Ook op deze dag in ${event.venue?.city?.name}` }}
    </EventBundleSection>

    <EventBundleSection
        v-if="event.disciplines && event.disciplines.length > 0 && eventHasCity"
        :lazy="true"
        :event-bundle-query="relatedDisciplineEventBundle"
        presentation="slider"
        section-width="bleed"
    >
        <template #title>
            {{ `Meer ${event.disciplines[0].name} in ${event.venue?.city?.name}` }}
        </template>
    </EventBundleSection>
</template>

<script setup lang="ts">
import {
    parseISO,
    format
} from 'date-fns';
import type { EventDate } from '~/@types/data';
import type { EventBundleRelated } from '~/composables/useEventBundle';

const props = defineProps<{
    event: EventDate
}>();

const eventHasCity = computed(() => props.event?.venue?.city?.id);

const defaultRelatedBundle: EventBundleRelated = {
    __typename: '[custom]event_bundle_related',
    skipEvent: props.event.id,
};

const relatedCityEventBundle = computed<EventBundleRelated>(() => {
    if (
        !props.event.venue
        || !props.event.venue.city
        || !props.event.venue.city.id
    ) {
        return defaultRelatedBundle;
    }

    const startDateObj = parseISO(props.event.start_date);
    const formattedDate = format(startDateObj, 'dd-MM-yyyy');

    return {
        ...defaultRelatedBundle,
        startDate: formattedDate,
        city: [
            {
                laravelModelId: props.event.venue.city.id
            }
        ],
    };
});

const relatedDisciplineEventBundle = computed<EventBundleRelated>(() => {
    if (
        !props.event.venue
        || !props.event.venue.city
        || !props.event.venue.city.id
        || !props.event.disciplines.length
    ) {
        return defaultRelatedBundle;
    }

    return {
        ...defaultRelatedBundle,
        discipline: [
            {
                laravelModelId: props.event.disciplines[0].id
            }
        ],
        city: [
            {
                laravelModelId: props.event.venue.city.id
            }
        ],
    };
});
</script>
