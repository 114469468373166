<template>
    <QueryEvent
        v-if="eventId"
        #default="{ event }"
        :event-id="typeof eventId === 'number' ? eventId.toString(10) : eventId"
    >
        <TemplatePanel
            v-if="event"
            class="event-detail"
        >
            <template #default>
                <!-- Hero -->
                <EventHero :event="event" />

                <!-- Quote -->
                <BaseSection
                    v-if="event.curatorQuote"
                    width="content-sm"
                    color-theme="light"
                >
                    <BaseTestimonial
                        :content="event.curatorQuote"
                        :image="event.curator?.featuredImage"
                        :author="event.curator?.name"
                        :title="event.curator?.sub_title"
                    />
                </BaseSection>

                <!-- Description -->
                <FlexibleSectionRichText
                    v-if="event.description"
                    color-theme="light"
                    :rich-text="getDescription(event.description)"
                />

                <!-- Trailer -->
                <FlexibleSectionTheVideo
                    v-if="event.trailerUrl"
                    color-theme="light"
                    title="Bekijk de trailer"
                    :video-url="event.trailerUrl"
                    :caption="getTrailerCaption(event)"
                />

                <!-- Call to action: logged out only -->
                <FlexibleSectionCallToAction
                    v-if="!authStore.loggedIn"
                    __typename="flexibleSections_callToAction_BlockType"
                    color-theme="light"
                    class="event-detail__call-to-action"
                    section-title="Wil je naar dit programma?"
                    rich-text="<p>Als je lid wordt van We Are Public krijg je onbeperkt toegang tot alle programma's op ons platform.</p>"
                    :call-to-action="{
                        text: 'Word lid',
                        type: becomeMemberText,
                        element: {
                            uri: SIGNUP_URL
                        }
                    }"
                    :image="[{
                        __typename: 'media_Asset',
                        w32: '/img/static/pas_32.png',
                        w320: '/img/static/pas_320.png',
                        w656: '/img/static/pas_656.png',
                        w1312: '/img/static/pas_1312.png',
                        width: 656,
                        height: 656,
                        title: 'De We Are Public-pas',
                        alt: 'De We Are Public-pas',
                        extension: '',
                        filename: '',
                        folderId: 0,
                        hasFocalPoint: false,
                        kind: '',
                        path: ''
                    }]"
                    @click="closeModal"
                />

                <FlexibleSectionCallToAction
                    v-if="authStore.loggedIn"
                    __typename="flexibleSections_callToAction_BlockType"
                    color-theme="light"
                    class="event-detail__call-to-action"
                    section-title="Geef dit programma aan een vriend"
                    rich-text="<p>Met de Vriendendienst deel je met één klik je persoonlijke link en geef je je vrienden een maand lang toegang tot alle programma's op ons platform.</p>"
                    :call-to-action="{
                        text: 'Lees meer over de Vriendendienst',
                        type: 'entry',
                        element: {
                            uri: FRIEND_URL
                        }
                    }"
                    :image="[{
                        __typename: 'media_Asset',
                        src: '/img/static/pas_double_1312.png',
                        w32: '/img/static/pas_double_32.png',
                        w320: '/img/static/pas_double_320.png',
                        w656: '/img/static/pas_double_656.png',
                        w1312: '/img/static/pas_double_1312.png',
                        width: 656,
                        height: 656,
                        title: 'De We Are Public-pas',
                        alt: 'De We Are Public-pas',
                        extension: '',
                        filename: '',
                        folderId: 0,
                        hasFocalPoint: false,
                        kind: '',
                        path: ''
                    }]"
                    @click="closeModal"
                />
            </template>

            <template #related>
                <EventRelated :event="event" />
            </template>
        </TemplatePanel>
    </QueryEvent>
</template>

<script setup lang="ts">
import type { EventDate } from '~/@types/data';
import { SIGNUP_URL, FRIEND_URL } from '~/constants/membership';
import { MODAL_MAIN, useModalStore } from '~/store/modal';
import { useGlobalStore } from '~/store/global';
import { useAuthStore } from '~/store/auth';

defineProps<{
    eventId: string|number|null
}>();

const authStore = useAuthStore();
const globalStore = useGlobalStore();
const modalStore = useModalStore();

const becomeMemberText = computed(() => {
    return globalStore.membershipTexts.becomeMemberCtaDefault;
});

const getDescription = (text: string) => {
        return `<h2>Over dit programma</h2>${text}`;
    },

    getTrailerCaption = (event: EventDate) => {
        let caption = event.trailerCaption || '';

        if (event.trailerCredits && event.trailerCaption) {
            caption += ` - ${event.trailerCredits}`;
        }

        if (event.trailerCredits && !event.trailerCaption) {
            caption = event.trailerCredits;
        }

        return caption;
    },

    closeModal = () => {
        modalStore.close({ name: MODAL_MAIN });
    };
</script>

<style lang="less" src="./PageEvent.less" />
